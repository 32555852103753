<template>
  <div>
    <Container class="bg-accent">
      <Navbar />

      <div class="w-7/12 pt-10 pb-14 relative">
        <h1>Terms of use</h1>
        <p class="text-xl text-grey-350 mt-4">
          Ydev Academy is to equip our students with the necessary digital and
          future skills to nurture confident, competent and imaginative
          professionals brave enough to pursue their ambitions.
        </p>
      </div>
    </Container>
    <Container class="bg-white pt-14 pb-32 ">
      <div class="grid grid-cols-4 space-x-14">
        <div class="w-5/6">
          <div class="flex flex-col space-y-2">
            <router-link to="/" class="text-lg text-primary">Introduction</router-link>
            <router-link to="/" class="text-lg">User Conduct Policy</router-link>
            <router-link to="/" class="text-lg">User Accounts, User Registration and Delivery of Services</router-link>
            <router-link to="/" class="text-lg">Payment Terms and Refund Policy</router-link>
          </div>
        </div>
        <div class="col-start-2 col-span-3">
          <div class="w-5/6">
            <h1>Introduction</h1>
            <p class="text-lg text-grey-350 mt-4">
              This is a legal and binding agreement between you, the user
              (referred to as “user” or “you”) of the Programs, as defined
              below, and upGrad Education Private Limited (referred to as “we”,
              “us” or “Upgrad”) stating the terms that govern your use of the
              Platform, as defined below. The website www.upgrad.com and mobile
              apps (collectively referred to as the “Platform”) and the
              information, services and other materials contained therein are
              provided and operated by Upgrad. Upgrad offers curated and
              specially designed online higher education and industry-relevant
              diploma, certification programs and career assistance services
              (“Programs”).
              <br /><br />
              Please review our Terms of Use, Privacy Policy and other policies
              available on the Platform (collectively referred to as the
              “Terms”) that govern the use of the Platform and Programs. By
              accepting these Terms in any manner or accessing the website, you
              consent, agree and undertake to abide, be bound by and adhere to
              the Terms and if you do not agree to these Terms, you are not
              entitled to avail of/use the Programs and any use thereafter shall
              be unauthorised.
              <br /><br />
              Each Program may have a separate set of terms dealing with
              refunds, deferrals, payments, etc. governing such Programs, and
              our corporate clients may have executed separate written
              agreements with us, which, in the event of a conflict, will
              supersede these Terms to the extent of the conflicting provisions.
              <br /><br />
              These Terms shall apply to Upgrad hosted mobile apps, WhatsApp
              groups, Facebook groups, Instagram pages, Facebook pages,
              email/SMS/phone communications and other social media forums
              hosted by Upgrad, which shall be deemed to be part of the
              ‘Platform’ by reference. You acknowledge that certain parts of the
              Platform, as mentioned above, are provided by third-party service
              providers, and you agree to abide by their terms and conditions.
              Upgrad shall not be responsible for any disruption of services
              caused by such third-party service providers.
              <br /><br />
              Although you may "bookmark" a particular portion of the Platform
              and thereby bypass these Terms, your use of the Platform still
              binds you to these Terms.
              <br /><br />
              We may change these Terms from time to time without prior notice.
              You should review this page regularly. Your continued use of the
              Platform and Programs after changes have been made will be taken
              to indicate that you have read and accepted those changes. You
              should not use the Platform or Programs if you are not happy with
              any changes to these Terms.
              <br /><br />
              Upgrad makes no representations that the Platform operates (or is
              legally permitted to operate) in all geographic areas, or that the
              Platform, or information, services or products offered through the
              Platform are appropriate or available for use in other locations.
              Accessing the Platform from territories where the Platform, or any
              content or functionality of the Platform or its portions thereof
              is illegal, is expressly prohibited. If you choose to access the
              Platform, you agree and acknowledge that you do so on your own
              initiative and at your own risk and that you are solely
              responsible for compliance with all applicable laws.
            </p>
          </div>
        </div>
      </div>
    </Container>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/blocks/Navbar.vue";
import Container from "@/components/blocks/Container";
import Footer from "@/components/blocks/Footer";

export default {
  name: "TermsOfUse",
  components: {
    Navbar,
    Container,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
</style>